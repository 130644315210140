<template>
  <Card>
    <template slot="outer">
      <div class="rounded align-center p-3 flex justify-between flex-wrap justify-sm-center justify-xs-center" style="background-color: #dbe2f5">
        <h2 class="text-lg font-bold text-primary text-uppercase m-1">Practice Materials <small class="text-gray-500">( 25 FILES )</small></h2>
        <div class="flex align-center m-1">
          <SelectComponent border-none class="bg-periwinkle-gray w-64 rounded" />
          <div class="bg-periwinkle-gray rounded p-2 ml-2">
            <md-icon class="m-0">filter_alt</md-icon>
          </div>
        </div>
      </div>
      <div class="md-layout my-6">
        <div class="md-layout-item">
          <p class="text-primary text-uppercase font-bold text-lg">
            Practice Material > Writing
          </p>
        </div>
        <div class="md-layout-item flex justify-end">
          <div class="flex align-center">
            <router-link :to="{ name: 'student.material.grid' }">
              <md-icon>view_comfy</md-icon>
            </router-link>
            <md-switch v-model="boolean" class="px-2 m-0"></md-switch>
            <router-link :to="{ name: 'student.material.list' }">
              <md-icon>format_list_bulleted</md-icon>
            </router-link>
            <router-link :to="{ name: 'student.material.directory' }">
              <md-icon class="ml-2">folder</md-icon>
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <CardHeader>
      <slot name="header"></slot>
    </CardHeader>
    <CardBody class="p-3">
      <router-view />
    </CardBody>
  </Card>
</template>

<script>
import SelectComponent from '@/components/atom/form/SelectComponent'
import { Card, CardHeader, CardBody } from "@/components";
export default {
  components: {
    Card,
    CardHeader,
    CardBody,
    SelectComponent
  },
  data() {
    return {
      boolean: "",
    };
  },
};
</script>